<template>
  <section class="aba-endereco-principal">
    <div class="pa-0 mt-4">
      <v-row>
        <v-col sm="12" md="3" cols="12" class="padding-bottom-half">
          <v-text-field
            label="CEP"
            :eager="true"
            :ref="`cep`"
            :key="`zip-code`"
            v-model="empresa.endereco.cep"
            :id="`cep`"
            :name="`cep`"
            v-validate="{
              required: true,
              zipCodeInvalid:
                buscaCep.length > 0 ? (buscaCep[''] ? true : false) : false,
            }"
            data-vv-validate-on="change|blur|input"
            @keyup="buscaPorCep(empresa.endereco.cep)"
            :error="errors.collect(`endereco.cep`).length ? true : false"
            :hint="errors.first(`endereco.cep`)"
            data-vv-as="cep"
            :data-vv-scope="`endereco`"
            v-mask="['##.###-###', '##.###-####']"
            persistent-hint
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="7" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Logradouro"
            :name="`logradouro`"
            v-validate="'required'"
            data-vv-scope="endereco"
            :hint="errors.first(`endereco.logradouro`)"
            persistent-hint
            :error="errors.collect(`endereco.logradouro`).length ? true : false"
            data-vv-as="logradouro"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.endereco.logradouro"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="2" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Número"
            :name="`numero`"
            v-validate="'required'"
            data-vv-scope="endereco"
            :hint="errors.first(`endereco.numero`)"
            persistent-hint
            :error="errors.collect(`endereco.numero`).length ? true : false"
            data-vv-as="nome da numero"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.endereco.numero"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="3" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Bairro"
            :name="`bairro`"
            v-validate="'required'"
            data-vv-scope="endereco"
            :hint="errors.first(`endereco.bairro`)"
            persistent-hint
            :error="errors.collect(`endereco.bairro`).length ? true : false"
            data-vv-as="nome do bairro"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.endereco.bairro"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Município"
            :name="`cidade`"
            v-validate="'required'"
            data-vv-scope="endereco"
            :hint="errors.first(`endereco.cidade`)"
            persistent-hint
            :error="errors.collect(`endereco.cidade`).length ? true : false"
            data-vv-as="nome do município"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.endereco.municipio"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="2" cols="12" class="padding-bottom-half">
          <v-text-field
            label="UF"
            :name="`uf`"
            v-validate="'required'"
            data-vv-scope="endereco"
            :hint="errors.first(`endereco.uf`)"
            persistent-hint
            :error="errors.collect(`endereco.uf`).length ? true : false"
            data-vv-as="sigla estado"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.endereco.uf"
            max-length="2"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Complemento"
            :name="`complemento`"
            persistent-hint
            v-model="empresa.endereco.complemento"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "enderecos",
  inject: ["$validator"],
  data: () => ({
    objetoForm: {},
    edicaoEndereco: false,
    buscaCep: [],
    loadingDeleteButton: false,
    loadingCheckButton: false,
    generalZipLogradrouro: true,
    generalZipBairro: true,
    zipCodeFound: false,
  }),
  methods: {
    trataDigitosCEP(cep) {
      let cepTratado = cep;
      if (cep.length == 7) {
        cepTratado = `0${cep}`;
      }
      if (cepTratado.length > 8) {
        cepTratado = cepTratado.substr(1);
      }
      return cepTratado;
    },
    buscaPorCep(cep) {
      let zip_code =
        cep !== undefined && cep !== ""
          ? this.$options.filters.numbersOnly(cep)
          : "";
      zip_code = this.trataDigitosCEP(zip_code);
      if (zip_code.length == 8) {
        return new Promise((resolve, reject) => {
          fetch(`https://viacep.com.br/ws/${zip_code}/json/`)
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data.erro !== undefined && data.erro) {
                this.zipCodeFound = false;
                this.empresa.endereco.numero = "";
                this.empresa.endereco.bairro = "";
                this.empresa.endereco.municipio = "";
                this.empresa.endereco.logradouro = "";
                this.empresa.endereco.uf = "";
                resolve(false);
              } else {
                this.empresa.endereco = Object.assign(
                  {},
                  this.empresa.endereco,
                  { ["bairro"]: "", ["logradouro"]: "", municipio: "", uf: "" }
                );
                this.zipCodeFound = true;
                this.generalZipLogradrouro = data.logradouro === "";
                this.generalZipBairro = data.bairro === "";
                this.empresa.endereco = Object.assign(
                  {},
                  this.empresa.endereco,
                  {
                    [!this.generalZipBairro && "bairro"]: data.bairro,
                    [!this.generalZipLogradrouro && "logradouro"]:
                      data.logradouro,
                    municipio: data.localidade,
                    uf: data.uf,
                  }
                );
                this.buscaCep = false;
                setTimeout(() => {
                  this.$validator.validate(`endereco.logradouro`);
                  this.$validator.validate(`endereco.bairro`);
                  this.$validator.validate(`endereco.uf`);
                  this.$validator.validate(`endereco.cidade`);
                  this.$validator.validate(`endereco.numero`);
                }, 100);
                resolve(true);
              }
            })
            .catch((error) => {
              this.zipCodeFound = false;
              this.buscaCep = false;
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "error",
                timeout: 5000,
                text: "Ops... Ocorreu um erro ao buscar o cep.",
              });
              reject(false);
            });
        });
      }
    },
  },
  computed: {
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaUsuarios"];
      },
      set(value) {
        this.$store.dispatch("empresas/setEmpresaUsuarios", value);
      },
    },
  },
};
</script>

<style></style>
